import React, { useState } from 'react';
import './ButtonGreen.css';

function ButtonGreen(props) {
    return <div className="ButtonGreen">
        <input type="button" className="ButtonGreen" value={ props.value } onClick={ props.click } />
    </div>;

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default ButtonGreen;