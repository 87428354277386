import React, { useState } from 'react';
import './EmphaticLabel.css';

function EmphaticLabel(props) {
    return <div className='EmphaticLabel'>
                <span className="EmphaticLabelSpan" id={props.myID}>{ "Currently Selected Product Group: " + props.label }</span>
    </div> ;

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default EmphaticLabel;
