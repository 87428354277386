import React, { useState } from 'react';
import './ProductGroupInventoryItem.css';

export default function ProductGroupInventoryItem(props) {
    if (props === null || typeof props == 'undefined') {
        return <div></div>;

    }
    console.log(JSON.stringify(props), props.item.id);
    return <div className="ProductGroupItem">
        <input type="checkbox" className="ProductGroupInventoryItem" data-id={props.item.id} cwitemid={props.item.cwItemId} name="ProductGroupInventoryItem" data-partnum={props.item.manufacturerPartNumber} data-description={props.item.partDescription} />
        <label htmlFor={props.item.cwItemId}>&nbsp;{props.item.manufacturerPartNumber} - {props.item.partDescription} - qty: {props.item.currentStockLevel}</label>
    </div>;

}

