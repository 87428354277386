import React, { useState, useEffect } from 'react';
//import { SafeAreaView, StyleSheet, TextInput } from 'react-native';
import './TextInput.css';

function TextInput(props) {

    // useEffect(() => {
    //     // Update the document title using the browser API
    //     console.log(text);
    // }), (text);

    return <div className="TextInput">
        {/* <TextInput
            //style={styles.input}
            onChangeText={onChangeText}
            value={text}
            id={props.myID}
        /> */}
        <input
            type="text"
            className="TextInput"
            id={props.myID}
            placeholder={props.placeholder}
            onChange={(e) => props.keyUpFunction(e.target.value)}
        />
    </div>;

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default TextInput;