
export const msalConfig = {
  auth: {
    clientId: "7362de85-c7d3-4137-bb84-e853e5290b1b",
    authority: "https://login.microsoftonline.com/3af3ed18-6578-404a-b7c1-654ad73a244e",
    redirectUri: "https://warehouse.roomready.io/",
  },
  cache: {
    cacheLocation: "sessionStorage", 
    storeAuthStateInCookie: false, 
  }
};

export const loginRequest = {
  scopes: ["openid"],
};  

