import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionStatus } from "@azure/msal-browser";

import './Content.css';
import ContentNewProductGroup from './ContentNewProductGroup.js';
import ContentEditProductGroup from './ContentEditProductGroup.js';

// export default function Content() {
//     //fetchStockBucket();

//     return <div className='Content'>
//         <ContentNewProductGroup />
//         <ContentEditProductGroup />
//     </div>;

// }

export default function Content(props) {
  const { instance, accounts, inProgress } = useMsal();
  const [apiKey, setApiKey] = useState(null);

  const [ctx, setctx] = useState({
    setCTX: setCTX,
    apiKey: null,
    new: {
      name: '',
      minimumQuantity: 0
    },
    edit: {
      doSearch: false,
      searchProductGroup: '',
      productGroups: [],
      inventoryToDelete: [],
      minimumStockLevel: 0,
      inventorySearchTerm: '',
      InventoryToAdd: []
    }
  });

  // useEffect(() => {
  //   setCTX(ctxTemplate);
  // }, []);

  function getCTX(newCTX) { // returns new instance of ctx
    console.log("Content.js/getCTX():", ctx);
    return JSON.parse(JSON.stringify(ctx));
  }
  function setCTX(newCTX) {
    console.log("Content.js/setCTX():", newCTX);
    setctx(newCTX);
  }

  useEffect(() => {console.log("ctx has changed:", ctx);}, [ctx]);
  useEffect(() => {
    async function fetchKey() {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      if (inProgress === InteractionStatus.None) {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        await instance
          .acquireTokenSilent(request)
          .then((response) => {
            const c = getCTX();
            console.log(c);
            c.apiKey = response.idToken;
            setCTX(c);
            setApiKey(response.idToken);
          })
          .catch(async (e) => {
            console.log(e);
            await instance.acquireTokenPopup(request).then((response) => {
              const c = getCTX();
              console.log(c);
              c.apiKey = response.idToken;
              setCTX(c);
              setApiKey(response.idToken);
            });
          });
      }
    }
    fetchKey();
  }, [instance, accounts, inProgress]);

  return (
    <div className='Content'>
      <ContentNewProductGroup ctx={ctx} setCTX={setctx} apiKey={ctx.apiKey} />
      <ContentEditProductGroup ctx={ctx} setCTX={setctx} apiKey={ctx.apiKey} />
    </div>
  );
}

