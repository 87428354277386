import React, { useState, useEffect } from 'react';
import './ProductGroupList.css';
import ProductGroupListItem from './ProductGroupListItem';
import { apiConfig } from "../apiConfig";

export default function ProductGroupList(props) {
    const [data, setData] = useState([]);                               // list of all available product groups

    useEffect(() => {
        console.log("props.productGroups initial:", props.productGroups);
    }, []);

    useEffect(() => {

    }, [props.isSearching]);

    useEffect(() => { // run once on load and when apiKey changes
        props.getProductGroups();
    }, [props.apiKey]);

    useEffect(() => { // creates entries in list of Product Groups when data changes
        if (data != null && data.length != 0) { // product groups return as an array
            console.log("data has changed:", JSON.stringify(data));
        }
    }, [data]);

    useEffect(() => {
        console.log("props.productGroups changed:", data);
        setData(props.productGroups);
    }, [props.productGroups]);

    if (props.productGroups != null && props.productGroups.length > 0) {
        console.log("props.productGroups:", data);
        if (props.isSearching) {
            return <div className="ProductGroupList"><span>Loading Data...</span></div>;
        }
        return <div className="ProductGroupList">
            {props.productGroups.map((item, index) => (
                <ProductGroupListItem key={index} itemIndex={index} itemId={item.id} name={item.name} click={props.click} />
            ))}
        </div>;
    }
    return <div className="ProductGroupList">
    </div>;
}

