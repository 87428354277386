import React, { useState } from 'react';
import logo from '../images/icon-roomready-cropped.png';
import './HeadingLogo.css';
function HeadingLogo(props) {

    return <div
        className="HeadingLogo"
    >
        <img
            src={logo}
            className="App-logo"
            alt="logo"
        />
    </div>

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default HeadingLogo;
