import React, { useState } from 'react';
import logo from '../images/logout-white.png';
import './HeadingLogout.css';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
// import { FetchStockBucket } from '../actions/apiCalls.js';

function HeadingLogo(props) {
    const isAuthenticated = useIsAuthenticated();
    console.log("isAuthed", isAuthenticated);
    // if (isAuthenticated) {
    //     FetchStockBucket();
    // }
    return <div class='HeadingLogout' >{isAuthenticated ? <SignOutButton /> : <SignInButton />}</div>
    // return <div className='HeadingLogout'><img src={logo} className="App-logo float-right" alt="logo" /></div> 

}

// function fetchStockBucket() {
//     fetch("/sandbox-stock-level/v1/stockbucket")
//         .then(response => {
//             console.log(response);
//         })
// }

// this 'export default' is what lets us import the function 
// and give it a custom name
export default HeadingLogo;
