import React, { useEffect } from 'react';
import './InventoryItem.css';

export default function InventoryItem(props) {
    // console.log(props.item);
    return <div className="editInventoryItem" id={ "eiic-" + props.item.id }>
        <input type="checkbox" className="editInventoryItem" id={ "eii-" + props.item.id } name="ProductGroupInventoryItem" data-cwpartnum={ props.item.id } data-mfgpartnum={ props.item.identifier } data-description={ props.item.description } />
        <label for={ "eii-" + props.item.id } id={ "eiil-" + props.item.id } >&nbsp;{ props.item.identifier } - { props.item.description } </label>
    </div>;

}

