import React, { useState, useEffect } from 'react';
// import { NewProductGroupPlus, npgPlusJ } from '../../actions/NewProductGroupPlus';
import '../../components/ContentProductGroup.css';
import './ContentNewProductGroup.css';
import ContentProductGroupCaption from '../../components/ColumnCaption';
import ContentProductGroupLabel from '../../components/ContentProductGroupLabel';
import ButtonGreen from '../../components/ButtonGreen';
import ButtonRound from '../../components/ButtonRound';
import TextInput from '../../components/TextInput';
import InventoryItems from '../../components/InventoryItems';
import { apiConfig } from "../../apiConfig";

// var apiKey = '';

function ContentNewProductGroup(props) {
    // console.log(props);

    //let url = "/sandbox-stock-level/v1/stockbucket";
    let endPoint = "/v1/stockbucket";
    //let endPoint = "/todos/1";

    const [skey, setSKey] = useState(null);
    const [newGroupName, setNewGroupName] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [inventory, setInventory] = useState(null);
    const [minCount, setMinCount] = useState(null);

    function getCTX(newCTX) { // returns new instance of ctx
        console.log("ContentNewProductGroup()getCTX():", props.ctx);
        return JSON.parse(JSON.stringify(props.ctx));
    }
    function setCTX(newCTX) {
        props.ctx.setCTX(newCTX);
        console.log("ContentNewProductGroup()setCTX():", newCTX);
    }

    useEffect(() => {
        if (props.ctx.apiKey !== null && props.ctx.apiKey !== 'undefined' && props.ctx.apiKey != "" && props.ctx.apiKey != skey) {
            // apiKey = props.apiKey;
            setSKey(props.apiKey);
            //FetchStockBucket(key);
        }
    }, [props.ctx.apiKey]);

    useEffect(() => {
        if (searchTerm == null && searchTerm.length > 0) {
            return;
        }
        console.log("Searching for:", searchTerm);
        searchInventory();
    }, [searchTerm]);

    useEffect(() => {
        console.log(newGroupName);
        document.getElementById('npgName').value = newGroupName;
        setMinCount(0);
    }, [newGroupName]);

    useEffect(() => {
        console.log(minCount);
        document.getElementById('npgMinStockLevel').value = minCount;
    }, [minCount])

    function plusEvent() { // Add a part to our product group

    }

    function searchInventory() {
        if (searchTerm == null || skey == null) { return; }
        console.log("ContentEditProductGroup()SearchInventory()", searchTerm, skey);

        fetch(apiConfig.uri + "/v1/cwsearchcatalog", {
            method: "POST",
            body: JSON.stringify({ name: searchTerm }),
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                console.log("InventoryItems() - AJAX:", "/v1/cwsearchcatalog", searchTerm, skey);
                console.log(JSON.stringify(json, 4), json);
                setInventory(json);
            })
            .catch(err => console.log(err));
    }

    function cEvent() { // "Create Notification" Click Event
        // console.log(props.apiKey);

        if (document.getElementById("npgName").value != "" &&
            document.getElementById("npgMinStockLevel").value != "" &&
            !isNaN(document.getElementById("npgMinStockLevel").value)) {
            
            console.log(props.ctx);
            
            fetch(apiConfig.uri + endPoint, {
                method: "POST",
                // mode: "no-cors",
                headers: new Headers({
                    Authorization: props.ctx.apiKey,
                }),
                body: JSON.stringify({
                    name: document.getElementById("npgName").value,
                    minimumStockLevel: document.getElementById("npgMinStockLevel").value
                })
            })
                .then((response) => {
                    // console.log("--NewStockBucket-- RETURNING:", response.json());
                    //return response.json();
                })
                .then((data) => {
                    // console.log("--NewStockBucket-- DATA", data);
                    let c = getCTX();
                    console.log(c);
                    c.edit.searchProductGroup = document.getElementById("npgName").value;
                    c.edit.doSearch = true;
                    console.log(c);
                    props.setCTX(c);
                    //setCTX(c);
                    console.log(c);

                    setNewGroupName(null);
                    // document.getElementById('npgName').value = 0;
                    // document.getElementById('npgMinStockLevel').value = 0;
                })
                .catch((err) => {
                    // console.log("--NewStockBucket-- ERR", err);
                });
        } else {
            alert("Valid values must be entered into Name and Minimum Stock Level.");
        }
    }

    return <div className='ContentProductGroup ContentNewProductGroup'>
        <ContentProductGroupCaption caption="New Product Group" />
        <ContentProductGroupLabel label="Create a name for the Product Group that you would like to create notifications about." />
        <TextInput placeholder="(Ex: 10 ft HDMI Cables)" myID='npgName' keyUpFunction={setNewGroupName} />
        <ContentProductGroupLabel label="Set the minimum stock level that will trigger a notification." />
        <TextInput placeholder="(Must be greater than 0)" myID='npgMinStockLevel' keyUpFunction={setMinCount} />
        {/* <ContentProductGroupLabel label="Enter search terms to narrow parts search." />
        <TextInput placeholder="Part No XYZ" myID='npgProduct' keyUpFunction={setSearchTerm} />
        <ContentProductGroupLabel label="Select parts to add to this group." inv={inventory} />
        <InventoryItems apiKey={props.apiKey} inventory={ inventory } className='editInventory' />
        <ButtonRound value="+" click={plusEvent} /> */}
        <ButtonGreen id="create" value="Create Notification" click={cEvent} />
    </div>;

}


export default ContentNewProductGroup;

