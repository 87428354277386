import React from 'react';
//import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

// const cors = require('cors');
// app.use(cors({
//     origin: 'https://localhost:3000'
// }));
// origin: 'https://rrtoolsapi.azure-api.net'

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

