// the page heading.  Duh!
import React, { useState } from 'react';
import './Heading.css';
import HeadingLogo from './HeadingLogo';
import HeadingCaption from './HeadingCaption';
import HeadingLogout from './HeadingLogout';

function Heading(props) {
    
    return <div className="Heading"><HeadingLogo className="Heading" /><HeadingCaption className="Heading" /><HeadingLogout className="Heading" /></div> 

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default Heading;
