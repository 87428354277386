import React, { useState } from 'react';
import './ProductGroupInventoryItems.css';
import ProductGroupInventoryItem from './ProductGroupInventoryItem';



function ProductGroupInventoryItems(props) {
    console.log("ProductGroupInventoryItems()props.partsList:", props.partsList);
    if (props.isSearching) {
        return <div className="ProductGroupInventoryItems" id={props.myID}>
            <span>Loading Data...</span>
        </div>;
    } else if (props.partsList != null && (typeof props.partsList != 'undefined')) {
        return <div className="ProductGroupInventoryItems" id={props.myID}>
            {props.partsList.map((item, index) => (
                <ProductGroupInventoryItem item={props.partsList[index]} />
            ))}
        </div>;
    } else {
        return <div className="ProductGroupInventoryItems" id={props.myID}>
        </div>;
    }

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default ProductGroupInventoryItems;