import React, { useState } from 'react';
import './ColumnCaption.css';

function ColumnCaption(props) {
    return <div className='ColumnCaption ContentNewProductGroup'>
                <span className="ColumnCaption">{ props.caption }</span>
    </div> ;

}

export default ColumnCaption;
