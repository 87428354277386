import React, { useState, useEffect } from 'react';
import './InventoryItems.css';
import InventoryItem from './InventoryItem';
import { apiConfig } from "../apiConfig";

export default function InventoryItems(props) {
    useEffect(() => {

    }, [props.isSearching]);
    
    console.log(props.inventory);
    let inv = props.inventory;
    if (props.isSearching) {
        return <div className="ProductGroupInventoryItems" id={props.myID} >
            <span>Loading Data...</span>
        </div>;
    }
    if (props.inventory != null && props.inventory.length > 0) {
        return <div className="ProductGroupInventoryItems" id={props.myID} >
            {inv.map((item, index) => (
                <InventoryItem item={item} />
            ))}
        </div>;
    }
    return <div className="ProductGroupInventoryItems">
    </div>;

}

