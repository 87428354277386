import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import logo from '../images/icon-roomready-cropped.png';
import './App.css';
import Heading from '../components/Heading.js';
import Content from './StockLevelNotifications/Content.js';
//import { apiCalls } from '../../components/apiCalls';

function App() {
  return (
    // <Layout>
    <Router>
      <Switch>
        <Route exact path="/">
          <AuthenticatedTemplate>
            <div className="App">
              <Heading />
              <Content />
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="App">
              <Heading />
              {/* <p>Please login...</p> */}
            </div>
          </UnauthenticatedTemplate>
        </Route>
      </Switch>
    </Router>
  );

}

export default App;
