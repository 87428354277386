import React, { useState } from 'react';
import './ContentProductGroupLabel.css';

function ContentProductGroupLabel(props) {
    return <div className='ContentProductGroupLabel'>
                <span className="ContentProductGroupLabel">{ props.label }</span>
    </div> ;

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default ContentProductGroupLabel;
