import React, { useEffect, useState } from 'react';
import '../../components/ContentProductGroup.css';
import './ContentEditProductGroup.css';
import ColumnCaption from '../../components/ColumnCaption';
import ContentProductGroupLabel from '../../components/ContentProductGroupLabel';
import ButtonGreen from '../../components/ButtonGreen';
// import ButtonRound from '../../components/ButtonRound';
import TextInput from '../../components/TextInput';
import EmphaticLabel from '../../components/EmphaticLabel';
import ProductGroupList from '../../components/ProductGroupList';
import ProductGroupInventoryItems from '../../components/ProductGroupInventoryItems';
import { apiConfig } from "../../apiConfig";
import InventoryItems from '../../components/InventoryItems';
import { DOM_KEY_LOCATION } from '@testing-library/user-event/dist/keyboard/types';

export default function ContentEditProductGroup(props) {
    //console.log(props.apiKey);
    let endPoint = "/v1/stockbucket";
    let jProductGroups = {};

    const [skey, setSKey] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [inventory, setInventory] = useState(null);
    
    const [productGroupSearchTerm, setProductGroupSearchTerm] = useState("");
    const [productGroupIsSearching, setProductGroupIsSearching] = useState(false);
    
    const [productGroups, setProductGroups] = useState(null);
    
    // currently selected product group applied to Emphatic label
    const [currentProductGroup, setCurrentProductGroup] = useState(null); 
    
    const [productGroupsView, setProductGroupsView] = useState(null); // stockbucketview in API
    
    const [productGroupInventoryIsSearching, setProductGroupInventoryIsSearching] = useState(false);
    
    const [productGroupInventory, setProductGroupInventory] = useState(null); // set inventory for currently selected product group
    const [inventoryIsSearching, setInventoryIsSearching] = useState(false);
    const [minStockLevel, setMinStockLevel] = useState(0);

    //console.log("ContentEditProductGroup()", skey);

    function getCTX(newCTX) { // returns new instance of ctx
        console.log("ContentEditProductGroup()getCTX():", props.ctx);
        return JSON.parse(JSON.stringify(props.ctx));
    }
    function setCTX(newCTX) {
        console.log("ContentEditProductGroup()setCTX():", newCTX);
        props.setCTX(newCTX);
    }

    function xSetCurrentProductGroup(e) {
        //alert("I've been clicked!!!");
        
        setProductGroupInventoryIsSearching(true);
        
        let n = parseInt(e.target.id.split('-')[1]);
        //console.log("click!", data[n].name); // event.target.getAttribute('data-name') 
        console.log("xClick!", e.target.getAttribute('data-name')); // event.target.getAttribute('data-name') 
        // getProductGroups();
        fetch(apiConfig.uri + "/v1/stockbucket/" + e.target.id.split('-')[1], {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": props.apiKey
            }
        })
            .then(response => response.json())
            .then(json => {
                setCurrentProductGroup(json);
            })
            .catch(err => console.log(err));
    }

    function searchProductGroups() {
        if (productGroupSearchTerm == null || typeof productGroupSearchTerm === "undefined" || productGroupSearchTerm == "" || skey == null) {
            getProductGroups();
            return;
        }
        console.log("ContentEditProductGroup()searchProductGroups()", productGroupSearchTerm);

        setProductGroupIsSearching(true);
        
        fetch(apiConfig.uri + "/v1/stockbucket?name=" + encodeURIComponent(productGroupSearchTerm), {
            method: "GET",
            // body: JSON.stringify({ name: productGroupSearchTerm }),
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                console.log("GET /v1/stockbucket?name=" + encodeURIComponent(productGroupSearchTerm), productGroupSearchTerm);
                console.log(JSON.stringify(json, 4), json);
                setProductGroups(json);
                setCurrentProductGroup(null);
                setProductGroupIsSearching(false);
            })
            .catch(err => {
                console.log(err)
                getProductGroups();
                setProductGroupIsSearching(false);
            });
    }

    function getProductGroups() {
        if (skey == null) {
            return;
        }
        console.log("ContentEditProductGroup()getProductGroups()", skey);
        
        setProductGroupIsSearching(true);
        
        fetch(apiConfig.uri + "/v1/stockbucketview", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                setProductGroups(json.stockBuckets);
                console.log("||||||||||||||||||||", json);
                setProductGroupIsSearching(false);
            })
            .catch(err => {
                console.log(err);
                setProductGroupIsSearching(false);
            });

    }

    function searchInventory() {
        if (searchTerm == null || skey == null) { return; }
        console.log("ContentEditProductGroup()SearchInventory()", searchTerm, skey);
        setInventoryIsSearching(true);

        fetch(apiConfig.uri + "/v1/cwsearchcatalog", {
            method: "POST",
            body: JSON.stringify({ name: searchTerm }),
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                setInventoryIsSearching(false);
                console.log("InventoryItems() - AJAX:", "/v1/cwsearchcatalog", searchTerm, skey);
                console.log(JSON.stringify(json, 4), json);
                setInventory(json);
            })
            .catch(err => {
                setInventoryIsSearching(false);
                console.log(err)
            });
    }

    function ContentNewAddParts() { }
    function ContentEditAddParts() {
        console.log("adding parts from edit section");
    }
    function cEventUpdate() { // click event for update button
        if (currentProductGroup == null) {
            alert("you must select a product group before updating.");
            return;
        }
        console.log(currentProductGroup);
        // minStockLevel
        updateMinStockLevel();
        // add parts
        addPartsToProductGroup();
        // delete parts
        removePartsFromProductGroup();
    }

    function cEventDelete() {
        if (currentProductGroup == null) {
            alert("No Product Group has been selected.");
            return;
        }
        console.log("DELETE");
        fetch(apiConfig.uri + "/v1/stockbucket/" + currentProductGroup.id, {
            method: "DELETE",
            // body: JSON.stringify({ name: currentProductGroup.name, minimumStockLevel: minStockLevel }),
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                console.log(JSON.stringify(json, 4), json);
                //setProductGroups(json);
                setCurrentProductGroup(null);
                getProductGroups();
            })
            .catch(err => console.log(err));
    }

    function updateMinStockLevel() {
        fetch(apiConfig.uri + "/v1/stockbucket/" + currentProductGroup.id, {
            method: "PUT",
            body: JSON.stringify({ name: currentProductGroup.name, minimumStockLevel: minStockLevel }),
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                console.log("updateMinStockLevel() - AJAX:", "/v1/stockbucket", productGroupSearchTerm, skey);
                console.log(JSON.stringify(json, 4), json);
                // setProductGroupList(json);
            })
            .catch(err => console.log(err));
    }
    function addPartsToProductGroup() {
        let ii = document.getElementById('EditInventoryItems');
        if (ii == null) { return; }
        console.log(document.getElementById('EditInventoryItems').getElementsByTagName('input'));
        if (document.getElementById('EditInventoryItems').getElementsByTagName('input') == null) { return; }
        let invItems = [].slice.call(document.getElementById('EditInventoryItems').getElementsByTagName('input'), 0);
        if (invItems.length === 0) { return; }
        console.log(invItems);
        let jnew = [];
        invItems.forEach(element => {
            if (element.checked === true) {
                console.log(element);
                // let jTemp = {cwItemId: element.dataset.cwpartnum, manufacturerPartNumber: element.getAttribute('mfgpartnum').value, stockBucketId: currentProductGroup.id};
                let jTemp = { cwItemId: element.dataset.cwpartnum, manufacturerPartNumber: element.dataset.mfgpartnum, stockBucketId: currentProductGroup.id, partDescription: element.dataset.description };
                jnew.push(jTemp);
                fetch(apiConfig.uri + "/v1/stockbucketitem", {
                    method: "POST",
                    body: JSON.stringify(jTemp),
                    headers: {
                        Accept: "application/json",
                        Authorization: skey,
                        // Content-Type: 'application/json' 
                    }
                })
                    .then(response => response.json())
                    .then(json => {
                        console.log("addPartsToProductGroup() - AJAX:", "/v1/stockbucket", productGroupSearchTerm, skey);
                        console.log(JSON.stringify(json, 4), json);
                        // setProductGroupList(json);
                        getProductGroupInventoryItems();
                        // clear checked items in inventory list
                        unCheckInventoryItems();
                    })
                    .catch(err => {
                        console.log(err)
                        getProductGroupInventoryItems();
                        // clear checked items in inventory list
                        unCheckInventoryItems();
                    });
            }
        });
        console.log(jnew);
        if (jnew.length === 0) { return; }
    }
    function removePartsFromProductGroup() {
        let ii = document.getElementById('EditInventoryCurrent');
        if (ii == null) { return; }
        console.log(document.getElementById('EditInventoryCurrent').getElementsByTagName('input') == null);
        if (document.getElementById('EditInventoryCurrent').getElementsByTagName('input') == null) { return; }
        let invItems = [].slice.call(document.getElementById('EditInventoryCurrent').getElementsByTagName('input'), 0);
        if (invItems.length === 0) { return; }
        console.log(invItems);
        let jnew = [];
        invItems.forEach(element => {
            if (element.checked === true) {
                console.log(element);
                console.log(element.dataset);
                console.log(element.dataset.id);
                // let jTemp = {cwItemId: element.dataset.cwpartnum, manufacturerPartNumber: element.getAttribute('mfgpartnum').value, stockBucketId: currentProductGroup.id};
                let jTemp = { cwItemId: element.dataset.cwItemId, manufacturerPartNumber: element.dataset.manufacturerPartNumber, stockBucketId: currentProductGroup.id };
                jnew.push(jTemp);
                fetch(apiConfig.uri + "/v1/stockbucketitem/" + element.dataset.id, {
                    method: "DELETE",
                    // body: JSON.stringify(jTemp),
                    headers: {
                        Accept: "application/json",
                        Authorization: skey,
                        // Content-Type: 'application/json' 
                    }
                })
                    .then(response => response.json())
                    .then(json => {
                        console.log("addPartsToProductGroup() - AJAX:", "/v1/stockbucket", productGroupSearchTerm, skey);
                        console.log(JSON.stringify(json, 4), json);
                        // setProductGroupList(json);
                        getProductGroupInventoryItems();
                        unCheckProductGroupInventoryItems();
                    })
                    .catch(err => {
                        console.log(err)
                        getProductGroupInventoryItems();
                        unCheckProductGroupInventoryItems();
                    });
            }
        });
        console.log(jnew);
        if (jnew.length === 0) { return; }
    }

    function getProductGroupInventoryItems() {
        setProductGroupInventoryIsSearching(true);
        fetch(apiConfig.uri + "/v1/stockbucketview", {
            method: "GET",
            //body: JSON.stringify({ name: productGroupSearchTerm }),
            headers: {
                Accept: "application/json",
                Authorization: skey,
                // Content-Type: 'application/json' 
            }
        })
            .then(response => response.json())
            .then(json => {
                setProductGroupInventoryIsSearching(false);
                setProductGroupsView(json);
                json.stockBuckets.forEach(element => {
                    if (element.id === currentProductGroup.id) {
                        console.log("json.stockBuckets: MATCH FOUND");
                        setProductGroupInventory(element.stockBucketItems);
                    }
                });
            })
            .catch(err => {
                setProductGroupInventoryIsSearching(false);
                // console.log(err);
            });
    }
    
    function unCheckInventoryItems() {
        const invItems = document.getElementsByClassName("editInventoryItem");
        Array.from(invItems).forEach(el => {
            el.checked = false;
        });
    }

    function unCheckProductGroupInventoryItems() {
        const invItems = document.getElementsByClassName("ProductGroupInventoryItem");
        Array.from(invItems).forEach(el => {
            el.checked = false;
        });
    }

    useEffect(() => { // currently selected product group has changed so we should load the inventory items for the new product group
        console.log(currentProductGroup);
        if (currentProductGroup == null || typeof currentProductGroup === 'undefined' || currentProductGroup === '') {
            document.getElementsByClassName("ContentProductGroupOptions")[0].style.display = "none";
            return;
        }
        document.getElementsByClassName("ContentProductGroupOptions")[0].style.display = "block";
        console.log("Current Product Group:", currentProductGroup.minimumStockLevel, currentProductGroup);
        setMinStockLevel(currentProductGroup.minimumStockLevel);
        getProductGroupInventoryItems();
    }, [currentProductGroup]);

    useEffect(() => {
        // make sure text field reflects the current value
        document.getElementById("ContentEditMinLevel").value = minStockLevel;
    }, [minStockLevel]);

    useEffect(() => {
        console.log("productGroupInventory:", productGroupInventory);
    }, [productGroupInventory]);

    useEffect(() => {
        console.log("productGroupSearchTerm:", productGroupSearchTerm);
        setProductGroupInventoryIsSearching(true);
        if (productGroupSearchTerm == null || typeof productGroupSearchTerm == 'undefined' || productGroupSearchTerm.length == 0) {
            getProductGroups();
            return;
        }
        let c = getCTX();
        c.edit.doSearch = false;
        setCTX(c);
        searchProductGroups();
    }, [productGroupSearchTerm]);

    useEffect(() => { // every time this component re-renders.
        if (productGroups == null) {
            searchProductGroups();
        }
    });

    useEffect(() => {
        console.log("ProductGroups have changed:", productGroups);
        if (
            productGroupSearchTerm != null &&
            typeof productGroupSearchTerm != 'undefined' &&
            productGroupSearchTerm != "" &&
            productGroups != null &&
            productGroups.length > 0) {
            productGroups.forEach(pg => {
                if (pg.name != null &&
                    typeof pg.name != 'undefined' &&
                    pg.name.length > 0 &&
                    pg.name === productGroupSearchTerm) {

                }
            });
        }
    }, [productGroups]);

    useEffect(() => {
        if (props.ctx.edit.doSearch) {
            console.log("I've been told to search...", props);
            document.getElementById("prodGrpSearchTerm").value = props.ctx.edit.searchProductGroup;
            setProductGroupSearchTerm(props.ctx.edit.searchProductGroup);
        }
    }, [props.ctx.edit.doSearch]);

    useEffect(() => {
        console.log(props.ctx);
        if (typeof props.ctx.edit.searchProductGroup !== 'undefined') {
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>", props.ctx.edit.searchProductGroup);
        }
    }, [props.ctx.edit.searchProductGroup]);

    useEffect(() => {  // inventory search
        if (searchTerm == null || searchTerm.length == 0) {
            return;
        }
        console.log("Searching for:", searchTerm);
        searchInventory();
    }, [searchTerm]);

    useEffect(() => { // every time we re-render this component
        if (props.apiKey != null && props.apiKey != "" && props.apiKey != skey) {
            console.log("#######################", props.apiKey);
            setSKey(props.apiKey);
        }
    });

    return <div className='ContentProductGroup ContentEditProductGroup'>
        <ColumnCaption caption="Edit Product Group" />
        <ContentProductGroupLabel label="Search the name of the product group you would like to edit." />
        <TextInput myID="prodGrpSearchTerm" placeholder="(Ex: 10 ft HDMI Cables)" keyUpFunction={setProductGroupSearchTerm} />
        <ContentProductGroupLabel label="And select from the list below." />
        <ProductGroupList apiKey={props.apiKey} click={xSetCurrentProductGroup} isSearching={productGroupIsSearching} productGroups={productGroups} getProductGroups={searchProductGroups} />
        <div className='ContentProductGroupOptions' >
            {
                (currentProductGroup != null && typeof currentProductGroup != 'undefined' && currentProductGroup.name != null && typeof currentProductGroup.name != 'undefined' && currentProductGroup.name != "")
                ? <EmphaticLabel miID="SelectedGroupName" label={ currentProductGroup.name } />
                :  <EmphaticLabel miID="SelectedGroupName" label="" />
            }
            <ContentProductGroupLabel label="Select any products you would like to remove from this product group." />
            <ProductGroupInventoryItems isSearching={productGroupInventoryIsSearching} partsList={productGroupInventory} myID="EditInventoryCurrent" />
            <ContentProductGroupLabel label="Set the minimum stock level that will trigger a notification." />
            <TextInput placeholder="(Must be greater than 0)" keyUpFunction={setMinStockLevel} myID="ContentEditMinLevel" />
            <ContentProductGroupLabel label="Enter search terms to narrow parts search." />
            <TextInput myID="InventorySearchCriteria" placeholder="Example: 'HDMI'" keyUpFunction={setSearchTerm} />
            <ContentProductGroupLabel label="Select parts to add to this group." inv={inventory} />
            <InventoryItems apiKey={props.apiKey} isSearching={inventoryIsSearching} inventory={inventory} className='editInventory' myID="EditInventoryItems" />
            {/* <ButtonRound value="+" click={ContentEditAddParts} /> */}
            <ButtonGreen id="update" value="Update Notification" click={cEventUpdate} />
            <ButtonGreen id="delete" value="Delete Notification" click={cEventDelete} />
        </div>
    </div>;

}

