import React, { useState } from 'react';
import './HeadingCaption.css';

function HeadingCaption(props) {

    return <div className='HeadingCaption'>
        <span>RoomReady - Stock Level Notifications</span>
    </div>;

}

// this 'export default' is what lets us import the function 
// and give it a custom name
export default HeadingCaption;
